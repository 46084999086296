<template>
  <div class="flex flex-col pt-7 font-Inter">
    <div class="flex flex-col bg-white shadow px-6 pt-4 mb-6 customer-white">
      <div class="flex flex-row mb-4 items-center justify-end">
        <router-link class="mr-2" to="/stock/sortie-stock/new">
          <el-button type="primary" icon="el-icon-plus">Créer</el-button>
        </router-link>
        <el-input
          class="mr-4"
          placeholder="Entrer le numéro du bon "
          v-model="listQuery.numero"
          @keydown.native.enter="getBonSortie()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-select
          style="width: 30%"
          class="mr-2"
          v-model="listQuery.raison"
          placeholder="Motif"
          clearable
          filterable
          @clear="handleClearBS"
          @change="getBonSortie()"
        >
          <el-option
            v-for="item in types"
            :key="item.name"
            :label="`${item.libelle}`"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-date-picker
          style="width: 25%"
          class="mr-2"
          v-model="listQuery.start"
          type="date"
          format="dd MMMM yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          style="width: 25%"
          class="mr-2"
          v-model="listQuery.end"
          type="date"
          format="dd MMMM yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button @click="getBonSortie()" class="">Recherche</el-button>
      </div>
      <div class="customer-white">
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else style="width: 100%">
          <el-table-column prop="date" label="DATE" width="200">
            <template slot-scope="{ row }">
              <span>{{ row.crated_at }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="numero" label="NUMERO" width="150">
            <template slot-scope="{ row }">
              <router-link :to="`/stock/sortie-stock/${row.number}`">
                <span class="text-blue-500 cursor-pointer">{{
                  row.number
                }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="cancel" label="STATUT" width="140">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>
          <el-table-column label="Type" min-width="220" align="left">
            <template slot-scope="{ row }">
              <span>{{ row.raison }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="count_items"
            align="right"
            label="TOTAL "
            width="180"
          >
            <template slot-scope="{ row }">
              <span> {{ row.total | moneyFilter }} </span>
            </template>
          </el-table-column>
          <el-table-column width="100" label="Action" align="center">
            <template slot-scope="{ row, $index }">
              <el-button
                @click="handleCancel(row.numero, $index)"
                size="mini"
                icon="el-icon-printer"
                circle
                :loading="selectedIndex === $index ? true : false"
              >
              </el-button>
              <el-button
                @click="handleCancel(row.numero, $index)"
                type="danger"
                size="mini"
                icon="el-icon-delete"
                circle
                :loading="selectedIndex === $index ? true : false"
                v-if="row.status === 'CLOSED'"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="getBonSortie()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchVouchers, fetchRaisonVouchers, cancelVoucher } from "@/api/stock";

import Pagination from "@/components/Pagination";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import SkeletonList from "@/components/Skeleton/list";
import Status from "@/components/Status";

export default {
  name: "OUTINGVoucher",
  components: { Pagination, SkeletonList, Status },
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      type: "OUTING_VOUCHER",
      selectedIndex: undefined,
      items: [],
      listLoading: false,
      loading: false,
      types: [],
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        raison: undefined,
        numero: undefined,
        start: undefined,
        end: undefined,
        sortDir: "date",
      },
    };
  },
  mounted() {
    this.getType();
    this.getBonSortie();
  },
  methods: {
    async getBonSortie() {
      this.listLoading = true;

      if (this.listQuery.numero == "") {
        this.listQuery.numero = undefined;
      }

      await fetchVouchers(this.listQuery, this.type)
        .then((res) => {
          setTimeout(() => {
            const items = res.data.items;
            this.items = items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => console.log(error));
    },
    async getType() {
      await fetchRaisonVouchers().then((res) => {
        this.types = res.data;
      });
    },

    handleClearBS() {
      this.listQuery.raison = undefined;
    },
    async handleCancel(id, index) {
      this.selectedIndex = index;

      await cancelVoucher(id, this.type)
        .then(() => {
          setTimeout(() => {
            this.$message({
              type: "success",
              duration: 5000,
              message: "Bon d'entrée a été annulé !",
            });

            this.selectedIndex = undefined;
            const bon = this.items.find((el) => el.numero === id);
            bon.status = true;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          let error = err.data;
          setTimeout(() => {
            if (error.status === 409) {
              this.$message({
                type: "danger",
                duration: 5000,
                message: error.message,
              });
            }
            this.selectedIndex = undefined;
          }, 1.5 * 1000);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
